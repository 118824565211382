<template>
  <dash-page-new
      :title="$t('News')"
      :subtitle="$t('AllNews')"
      :root="$t('Dashboard')"
      :root-location="businessDashLink()"
      icon="mdi-newspaper-variant"
      side-user >

    <template #header_action>
      <v-btn @click="addNews"  dark  :color="wsATTENTION"  class="noCaps">
        <v-icon small>mdi-plus</v-icon>
        {{ $t('AddNews') }}
      </v-btn>
    </template>

    <template #default>
      <v-data-table
          :items="news"
          :headers="headers"
          hide-default-header
          class="mt-6"
          selectable-key="id" >

        <template v-slot:header="{ props }">
          <wsTableHeader :props="props" />
        </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>
                <router-link
                    :to="businessDashLink('news/' + item.uuid)"
                    :style="`color : ${wsACCENT}`"
                    class="noUnderline linkHover"
                >
                  <div class="d-flex align-center ">
                    <h5 class=" mr-3">{{ item.title }}</h5>
                  </div>
                </router-link>


              </td>
              <td>
                <ws-chip  icon="mdi-calendar" :color="wsACCENT">
                  <h5 class=" text-no-wrap" >{{ PARSE_DATE(item.date) }}</h5>
                </ws-chip>
              </td>
              <td align="center">
                <v-chip v-if="item.published" small color="green lighten-1" outlined>
                  <h5>{{  $t('Published')  }} </h5>
                </v-chip>
                <h5 v-else class="font-weight-light">{{ $t('Draft') }}</h5>
              </td>
            </tr>

          </template>

        </v-data-table>
    </template>

    <template #side>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "BusinessDashboardNews",
  data (){
    return {
     news : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name')  , value : 'title'    },
        { text : this.$t('Date')  , value : 'date'     , width: 10, align : 'center' , sortable : false },
        { text : this.$t('Status'), value : 'published', width: 10, align : 'center' },
        { value : 'action', width : 10, sortable : false }
      ]
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_ADMIN_BUSINESS_NEWS_ALL','ADD_EDIT_BUSINESS_NEWS']),

    async addNews() {
      let result = await this.ADD_EDIT_BUSINESS_NEWS()
      if ( !result ) { return }
      this.news.unshift(result)
    },
    editNews(input) {
      this.displayEditorNews = true;
      this.dataEditType = 'edit',
      this.dataEditInput = input;
    },

  },
  async mounted(){
    let result = await this.GET_ADMIN_BUSINESS_NEWS_ALL()
    this.news = result.news
  }

}
</script>

<style scoped>

</style>